// ** React Imports
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';

// ** Intl, CASL & ThemeColors Context
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';

// ** ThemeConfig
import themeConfig from './configs/themeConfig';

// ** Toast
import { Toaster } from 'react-hot-toast';

// ** i18n
import './configs/i18n';

// ** Spinner (Splash Screen)
//import Spinner from './@core/components/spinner/Fallback-spinner'
import SpinnerXefi from './views/components/spinners/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/themes/prism-tomorrow.css';

// ** React Perfect Scrollbar
import 'devextreme/dist/css/dx.light.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss';

// ** Core styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/breadcrumb-custom.scss';
import './assets/scss/swal-custom.scss';
import './assets/scss/toast.scss';
import './assets/scss/style.scss';

// ** Service Worker
import { frFR } from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';

// ** Utils
import useAuth from 'utility/hooks/useAuth';
import { guidingTool } from 'utility/guiding';
import { useEffect } from 'react';


// ** DevExtreme language
// Pas de solution trouvée pour résoudre ce problème pour l'instant
// require('devextreme/localization/globalize/message');
// require('devextreme/localization/globalize/number');
// require('devextreme/localization/globalize/currency');
// require('devextreme/localization/globalize/date');
require('devextreme/localization');

// ** DevExtreme globalize
import * as Globalize from 'globalize/dist/globalize';

// ** DevExtreme globalize
Globalize.load(
  // language specific files, loading English and German here
  require('cldr-data/main/en/ca-gregorian.json'),
  require('cldr-data/main/en/currencies.json'),
  require('cldr-data/main/fr/ca-gregorian.json'),
  require('cldr-data/main/fr/numbers.json'),
  require('cldr-data/main/fr/currencies.json'),
  require('cldr-data/supplemental/likelySubtags.json'),
  require('cldr-data/supplemental/timeData.json'),
  require('cldr-data/supplemental/weekData.json'),
  require('cldr-data/supplemental/currencyData.json'),
  require('cldr-data/main/fr/numbers.json')
);

// German messages - English ones are included by default
const frMessages = require('devextreme/localization/messages/fr.json');

const localization = require('devextreme/localization');

// This loading instruction is not required when using CDN paths
localization.loadMessages(frMessages);
localization.locale('fr');

const dataGridTheme = createTheme(
  {
    palette: {
      primary: { main: '#E20917' },
    },
  },
  frFR
);

// ** L
const LazyApp = lazy(() => import('./App'));

Sentry.init({
  dsn: 'https://c7283dc5d7924636a6420e78b8f390d6@sentry.lebureauvirtuel.fr/11',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV_TYPE,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const { ability } = useAuth();

// useEffect(() => {
//   if (['prod'].includes(process.env.ENV_TYPE)) {
//     guidingTool(
//       window,
//       document,
//       'https://www.usetiful.com/dist/usetiful.js'
//     );
//   }
// }, []);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<SpinnerXefi />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <ThemeProvider theme={dataGridTheme}>
              <LazyApp />
            </ThemeProvider>
            <Toaster
              position={themeConfig.layout.toastPosition}
              toastOptions={{ className: 'react-hot-toast' }}
            />
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
